import {FontWeights} from '@/themes';
import {InputKeys} from '@/themes/palletes/types';
import styled from 'styled-components';

type TWrapperProps = {
  margin: {
    top: string;
    left: string;
    right: string;
    bottom: string;
  };

  width: string;
};

type TContentProps = {
  color: keyof InputKeys;

  height: number;
  borderradius: number;
  missed?: boolean;
};

type TInputProps = {
  color: keyof InputKeys;

  weight: keyof typeof FontWeights;
};
type TCustomProps = {
  moveToTop?: boolean;
  color: keyof InputKeys;
  error?: boolean;
  date?: boolean;
};

export const Wrapper = styled.div<TWrapperProps>`
  margin: ${({margin: {right, bottom, left, top}}) =>
    `${top} ${right} ${bottom} ${left}`};
  width: ${({width}) => width};

  & input:focus {
    outline: none;
  }
`;

export const Label = styled.p`
  margin: 0;
  margin-bottom: 8px;
  color: ${({theme}) => theme.text.default};
  font-weight: ${FontWeights.medium};
`;

export const Content = styled.div<TContentProps>`
  background: ${({theme}) => theme.input.background.default};
  height: ${({height}) => height}px;
  transition: 500ms background;
  border: 1px solid
    ${({theme, color, missed}) =>
      missed && color !== 'accent_darker'
        ? theme.new_brand.warning_40
        : theme.input.border[color]};
  border-radius: ${({borderradius}) => borderradius}px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const InputWrapper = styled.div`
  flex: 1;
  position: relative;
`;

export const Input = styled.input<TInputProps>`
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  color: ${({color, theme}) => theme.input.text[color]};
  font-weight: ${({weight}) => FontWeights[weight]};
  transition: 500ms color;
  font-size: 16px;

  &::placeholder {
    align-self: start;
    font-weight: ${FontWeights.regular};
    color: ${({theme, color}) => theme.input.placeholder[color]};
    font-size: 16px;
  }
`;

export const Icon = styled.label`
  pointer-events: none;
`;

export const ErrorText = styled.p`
  color: ${({theme}) => theme.new_brand.danger_40};
  height: 16px;
  margin: 0;
  margin-top: 8px;
  font-size: 12px;
  position: relative;
`;

export const PasswordShowWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 4px;
`;
export const CustomText = styled.div<TCustomProps>`
  position: absolute;
  top: ${({moveToTop, date}) => (moveToTop ? (date ? '-20px' : '-10px') : '0')};
  font-size: ${({moveToTop}) => (moveToTop ? '12px' : '16px')};
  color: ${({theme, moveToTop}) =>
    moveToTop ? '#999999' : theme.input.placeholder};
  font-weight: 400;
  padding-left: 2px;
  pointer-events: none;
  transition: top 0.1s linear;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({error, moveToTop}) => (moveToTop || !error ? '100%' : '90%')};
  white-space: nowrap;
`;
