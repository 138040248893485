'use client';

import {Suspense, lazy, useEffect, useState} from 'react';
import {
  CardWrapper,
  GridCardWrapper,
  SearchWrapper,
  Wrapper,
} from './AllListings.style';
import {useData} from './useAllListings';
import successGif from '@/assets/images/Dashboard/success.json';
import {SkeletonCard} from '@/components/Cards/SkeletonCard';
import {Button} from '@/components/Core/Button';
import {FilledField} from '@/components/Core/FilledField';
import {GridAllHouseCard} from '@/components/Cards';
import {MiniSearchIcon} from '@/components/Icons';

import ShowingRequested from '@/features/showings/components/Modals/ShowingRequested';
import {parseISO, format} from 'date-fns';
import {toZonedTime as utcToZonedTime} from 'date-fns-tz';
import Text from '@/components/ui/Text';
import {Booking} from '@/features/showings/types';
import {BookingStatusIds} from '@/features/showings/constants';
import {usePathname, useSearchParams} from 'next/navigation';
import dynamic from 'next/dynamic';
import Skeleton from '@/components/ui/Skeleton';
import {
  OfferSeenText,
  SuccessModalWrapper,
} from '@/app/(legacy)/(container)/agent/dashboard/Dashboard.styles';
import Container from '@/components/ui/Container';

const ModalLayout = dynamic(() => import('@/layouts/ModalLayout'), {
  ssr: false,
});

const AllListingActionBar = dynamic(
  () => import('@/features/listings/components/AllListingActionBar'),
  {
    loading: () => (
      <Skeleton
        height={38}
        width="100%"
        style={{
          margin: '20px 0 16px',
        }}></Skeleton>
    ),
    ssr: false,
  },
);

const Lottie = lazy(() => import('lottie-react'));

const AllListings = () => {
  const {listings, setValue, isLoading, handleSortChange, sortType} = useData();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    searchParams.get('offerSuccess') == 'true' && setAnimation(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const [successBookingInfo, setSuccessBookingInfo] = useState<Booking | null>(null)

  useEffect(() => {
    setSuccessBookingInfo(JSON.parse(
      sessionStorage.getItem('scheduleShowingSuccess') || '{}',
    ))
  }, [])

  const closeAnimationModal = () => {
    sessionStorage.removeItem('scheduleShowingSuccess');
    setAnimation(false);
  };

  const options = {
    animationData: successGif,
    autoplay: true,
    loop: false,
  };

  return (
    <div>
      {successBookingInfo && successBookingInfo.uuid && (
        <ShowingRequested
          onOpenChange={closeAnimationModal}
          type={successBookingInfo.showing_type_display}
          listingAgent={{
            firstName: successBookingInfo.listing_agent.first_name,
            lastName: successBookingInfo.listing_agent.last_name,
          }}
          isConfirmed={
            successBookingInfo.listing_agent_status ===
            BookingStatusIds.confirmed
          }
          address={successBookingInfo.prop.address}
          time={{
            from: format(
              utcToZonedTime(parseISO(successBookingInfo.start_datetime), ''),
              'p',
            ),
            to: format(
              utcToZonedTime(parseISO(successBookingInfo.end_datetime), ''),
              'p',
            ),
          }}
          defaultOpen={true}
          buyers={[
            successBookingInfo.buyer_agent,
            ...successBookingInfo.guests,
          ]}
          date={format(
            utcToZonedTime(parseISO(successBookingInfo.start_datetime), ''),
            'MM.dd.yyyy',
          )}
          listingKey={successBookingInfo.prop.listing_key}
        />
      )}
      <ModalLayout isOpened={animation} onClose={() => closeAnimationModal()}>
        <SuccessModalWrapper>
          <Suspense>
            <Lottie {...options} />
          </Suspense>
          <div>Offer Sent!</div>
          <OfferSeenText>
            {`We'll notify you once your offer is seen!`}
          </OfferSeenText>
          <Button
            color="light_gray"
            onClick={() => closeAnimationModal()}
            width={'100%'}>
            Got It
          </Button>
        </SuccessModalWrapper>
      </ModalLayout>
      <Wrapper>
        <SearchWrapper>
          <Container>
            <FilledField
              variant="round"
              placeholder="Enter an address, city, zip code, agent name or listing ID"
              rightIcon={<MiniSearchIcon />}
              onChange={(item) => setValue(item.target.value)}
              width={'100%'}
            />
          </Container>
        </SearchWrapper>
        <AllListingActionBar
          onSortChange={handleSortChange}
          sortType={sortType}
        />
        {listings?.[0].data.results.length || isLoading ? (
          <CardWrapper>
            <GridCardWrapper>
              {listings?.map((page) => {
                return page.data.results.map((item, id) => (
                  <GridAllHouseCard item={item} type={0} key={id} />
                ));
              })}
              {Array.from({length: 3})?.map(
                (_, index) => isLoading && <SkeletonCard key={index} />,
              )}
            </GridCardWrapper>
          </CardWrapper>
        ) : (
          <Text size="p_medium">
            No results match your set filters or search term.
          </Text>
        )}
      </Wrapper>
    </div>
  );
};

export default AllListings;
