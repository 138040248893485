import {HTMLAttributes, forwardRef} from 'react';
import styles from './styles.module.css';
import {transformToPx} from '@/lib/helpers/styled';
import {cva, cx} from 'class-variance-authority';

type SkeletonProps = {
  /**
   * @deprecated Use CSS instead
   */
  width?: string | number;
  /**
   * @deprecated Use CSS instead
   */
  height?: string | number;
  variant?: 'rectangle' | 'circle';
} & Omit<HTMLAttributes<HTMLDivElement>, 'children'>;

const skeletonVariants = cva(styles.skeletonLoading, {
  variants: {
    variant: {
      rectangle: styles.rectangle,
      circle: styles.circle,
    },
  },
});

const Skeleton = forwardRef<HTMLDivElement, SkeletonProps>(
  ({width, height, variant = 'rectangle', className, ...props}, ref) => {
    const style = {
      width: width ? transformToPx(width) : undefined,
      height: height ? transformToPx(height) : undefined,
    };

    return (
      <div
        ref={ref}
        className={cx(
          skeletonVariants({
            variant,
          }),
          className,
        )}
        style={style}
        {...props}
      />
    );
  },
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
